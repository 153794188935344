import React from 'react';
import { useNavigate } from 'react-router-dom';

function QuizStartPage() {
  const navigate = useNavigate(); // Sử dụng hook để điều hướng

  const handleStartClick = () => {
    // Điều hướng đến trang chọn màn
    navigate('/quiz-selection');
  };
  return (
    <div className="relative w-full max-w-full lg:max-w-[500px] mx-auto">
    <div
      className="relative h-screen bg-cover bg-center overflow-hidden"
      style={{ backgroundImage: 'url(/images/IgHome/nen.png)' }}
    >
      {/* Title Images */}
      <div className="relative">
        {/* Sun and Buddha Image */}
        <img
          src="images/IgHome/phat.png"
          alt="Sun and Buddha"
          className="absolute left-[220px] md:left-[350px] lg:left-1/2 transform -translate-x-1/2 w-[250px]"
        />

        {/* AI LÀ PHẬT TỬ? Title Image */}
        <img
          src="images/IgHome/phude1.png"
          alt="AI LÀ PHẬT TỬ?"
          className="absolute top-[40px] left-1/2 transform -translate-x-1/2 w-72"
        />

        {/* QUIZZ PHẬT HỌC Title Image */}
        <img
          src="images/IgHome/phude2.png"
          alt="QUIZZ PHẬT HỌC"
          className="absolute top-[120px] left-1/2 transform -translate-x-1/2 w-72"
        />
        <img
          src="images/IgHome/may1.png"
          alt="Mây 1"
          className="absolute top-[30px] left-[30px] lg:left-[400px] transform -translate-x-1/2 w-[100px]"
        />
        <img
          src="images/IgHome/may1.png"
          alt="Mây 1"
          className="absolute top-[200px] right-[-30px] lg:right-[400px] transform w-[100px]"
        />
        <img
          src="images/IgHome/may2.png"
          alt="Mây 2"
          className="absolute top-[200px] left-[50px] lg:left-[500px] transform -translate-x-1/2 w-[80px]"
        />
        <img
          src="images/IgHome/may2.png"
          alt="Mây 2"
          className="absolute top-[30px] right-[10px] lg:right-[500px] transform w-[70px]"
        />
      </div>

      {/* Character */}
      <div className='relative'>
          <img src="/images/IgHome/hoasen.png" alt="Character" className="absolute top-[328px] left-[20px] lg:left-[600px] xl:left-[220px] transform w-[200px]" />
          <img src="/images/IgHome/chua.png" alt="Character" className="absolute top-[318px] right-[10px] lg:right-[600px] xl:right-[240px] transform w-[200px]" />
          <img src="/images/IgHome/embe.png" alt="Character" className="absolute top-[270px] left-1/2 transform -translate-x-1/2 w-[220px]" />
      </div>
      
      {/* Button */}
      <div className='relative'>
        <img onClick={handleStartClick}  src="/images/IgHome/batdau.png" alt="Character" className="absolute cursor-pointer top-[350px] left-1/2 transform -translate-x-1/2 w-[300px] z-40" />
        <img onClick={handleStartClick} src="/images/IgHome/Click.png" alt="Character" className="absolute top-[500px] left-2/3 xl:left-[1050px] transform -translate-x-1/2 w-[20px] z-40" />
      </div>

      {/* Ground Image */}
      <div className='relative h-screen'>
        <img
          src="/images/IgHome/dat.png"
          alt="Ground"
          className="absolute bottom-0 left-1/2 transform -translate-x-1/2 sm:h-auto lg:h-40 w-full z-20"
        />
      </div>
    </div>
    </div>
  );
}

export default QuizStartPage;
