

import { useNavigate } from 'react-router-dom';

function QuizSelectionScreen() {

  const navigate = useNavigate(); // Sử dụng hook để điều hướng

  // Gọi API để lấy danh sách các level
 
  const cardImages = [...Array(20)].map((_, index) => ({
    card: `/images/trang_chon_man/Element.png`, // Path for the card image
    number: `/images/trang_chon_man/${index + 1}.png`, // Path for number image (1.png, 2.png, ..., 20.png)
  }));
  const handleStartClick = () => {
    // Điều hướng đến trang chọn màn
    navigate('/');
  };
  const handleLevelSelect = (levelId) => {
    navigate(`/level/${levelId}`);
  };
  return (
    <div className="relative w-full max-w-full lg:max-w-[500px] mx-auto">
    <div
      className="relative h-screen bg-cover bg-[#bfe9ff] bg-center overflow-hidden"
    >
      {/* Grid for cards */}
      <div className="grid grid-cols-4 gap-6 justify-items-center mx-5 my-[10px] z-20 h-auto">
        {cardImages.map((item, index) => (
          <div key={index} onClick={() => handleLevelSelect(index + 1)} className="relative mb-2 z-10">
            {/* Card Image */}
            <img
              src={item.card}
              alt={`Card ${index + 1}`}
              className="w-[50px] mb-2 "
            />
            {/* Number Image */}
            <img
              src={item.number}
              alt={`Number ${index + 1}`}
              className="absolute bottom-[-10px] left-1/2 transform -translate-x-1/2 w-[30px] "
            />
          </div>
        ))}
      </div>

      {/* Background Image (like the grass and trees) */}
      <div className="absolute bottom-0 left-0 right-0">
        <img onClick={()=> handleStartClick()} src="/images/trang_chon_man/quay_ve.png" alt="Ground" className="absolute bottom-[30px] right-[20px] transform -translate-x-1/2 w-[60px] z-50"/>
        <img src="/images/trang_chon_man/dat.png" alt="Ground" className="w-full" />
      </div>
    </div>
    </div>
  );
}

export default QuizSelectionScreen;
