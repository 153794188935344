import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Example question data for each level
const questionsData = {
  1: {
    topic: "Chủ đề 1",
    questions: [
      {
        question: "Sám hối là gì?",
        options: [
          "Là lạy một trăm lạy",
          "Là làm lỗi...",
          "Là ăn năn...",
          "Cả A & B",
        ],
        correct: "D",
        explanation:
          "Sám hối có nghĩa là cả nhận thức sai lầm và quyết tâm không tái phạm.",
      },
      {
        question: "Question 2 for level 1",
        options: ["A", "B", "C", "D"],
        correct: "A",
        explanation: "",
      },
      {
        question: "Question 3 for level 1",
        options: ["A", "B", "C", "D"],
        correct: "C",
        explanation: "",
      },
    ],
  },
  2: {
    topic: "Chủ đề 2",
    questions: [
      {
        question: "Question 1 for level 2",
        options: ["A", "B đúng", "C", "D"],
        correct: "B",
      },
      {
        question: "Question 2 for level 2",
        options: ["A", "B", "C đúng", "D"],
        correct: "C",
      },
      {
        question: "Question 3 for level 2",
        options: ["A đúng", "B", "C", "D"],
        correct: "A",
      },
    ],
  },
  // More levels...
};

function QuizScreen() {
  const { levelId } = useParams(); // Lấy levelId từ URL
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [lives, setLives] = useState(3);
  const [topic, setTopic] = useState("");
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null); // Trạng thái câu trả lời
  const [completed, setCompleted] = useState(false); // Hoàn thành level
  const [outOfLives, setOutOfLives] = useState(false); // Hết lượt

  const navigate = useNavigate();

  useEffect(() => {
    const selectedQuestions = questionsData[levelId] || [];
    setQuestions(selectedQuestions.questions);
    setTopic(selectedQuestions.topic);
    setCurrentQuestionIndex(0);
    setLives(3);
    setCompleted(false);
    setOutOfLives(false);
  }, [levelId]);

  const handleAnswer = (selectedOption) => {
    if (questions[currentQuestionIndex].correct === selectedOption) {
      setIsAnswerCorrect(true);
    } else {
      setIsAnswerCorrect(false);
      if (lives - 1 === 0) {
        setOutOfLives(true); // Hết tim
      } else {
        setLives(lives - 1);
      }
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex + 1 < questions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setIsAnswerCorrect(null);
    } else {
      setCompleted(true); // Kết thúc level
    }
  };

  const handleFinish = () => {
    navigate("/quiz-selection");
  };

  if (questions.length === 0 || currentQuestionIndex >= questions.length) {
    return <div>Loading questions...</div>;
  }

  return (
    <div className="relative w-full max-w-full lg:max-w-[500px] mx-auto">
      <div className="relative h-screen bg-[#bfe9ff] flex flex-col">
        <button
          className="absolute top-5 left-5"
          onClick={() => navigate("/quiz-selection")}
        >
          <img
            src="/images/question/quay_ve.png"
            alt="Back"
            className="w-[40px]"
          />
        </button>

        <div className="absolute top-5 right-5 flex space-x-2">
          {Array(lives)
            .fill()
            .map((_, index) => (
              <img
                key={index}
                src="/images/question/tim_dam.png"
                alt="Heart"
                className="w-[30px]"
              />
            ))}
        </div>
        <div className="absolute top-5 right-5 flex space-x-2">
          {Array(3)
            .fill()
            .map((_, index) => (
              <img
                key={index}
                src="/images/question/tim_nhat.png"
                alt="Heart"
                className="w-[30px]"
              />
            ))}
        </div>

        <div className="flex-grow flex flex-col items-center justify-center h-auto ">
          <div className="relative mt-[30px] left-5 ">
            <div className="absolute left-2 top-[-30px] z-20 w-full">
              <p className="text-lg text-black font-semibold">
                Chủ đề: {topic}
              </p>
            </div>
            <div>
              <img
                src="/images/question/bang_cauhoi.png"
                alt="Question Box"
                className="w-[90%] lg:w-[90%] lg:h-[200px]"
              />
              <p className="absolute inset-0 flex items-center justify-center text-lg text-black mx-2 my-2">
                {questions[currentQuestionIndex].question}
              </p>
            </div>
            <div className="absolute font-semibold mt-2 text-lg text-black z-10 left-[35%]">
              Câu {currentQuestionIndex + 1}/{questions.length}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2 mt-10 z-50">
            {questions[currentQuestionIndex].options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleAnswer(String.fromCharCode(65 + index))} // A, B, C, D
                className="relative"
              >
                <div className="relative flex flex-col items-center">
                  <img
                    src={`/images/question/${index + 1}.png`}
                    alt={`Number ${index + 1}`}
                    className={`absolute ${
                      index < 2 ? "top-[-20px]" : "top-[100px]"
                    } w-[20px] mb-2`}
                  />
                  <img
                    src="/images/question/bang_traloi.png"
                    alt={`Option ${index}`}
                    className="w-[160px]"
                  />
                  <p className="absolute inset-0 flex items-center justify-center text-lg text-black px-2">
                    {option}
                  </p>
                </div>
              </button>
            ))}
          </div>
        </div>

        {/* Modal khi trả lời */}
        {isAnswerCorrect !== null && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="p-6 bg-white rounded-lg flex flex-col items-center">
              {isAnswerCorrect ? (
                <>
                  {/* Check if there is an explanation for this question */}
                  {questions[currentQuestionIndex].explanation ? (
                    <>
                      <div className="relative">
                        <img
                          src="/images/Modal/9.png"
                          alt="Correct with Explanation"
                          className="w-[500px] lg:w-[300px]"
                        />
                        <p className=" w-[43%] absolute top-[33%] right-0 mr-4 mt-4 text-green-500 text-xs font-bold">
                          {questions[currentQuestionIndex].explanation}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        src="/images/Modal/11.png"
                        alt="Correct"
                        className="w-[200px]"
                      />
                      <p className="text-green-500 font-bold">
                        Bạn đã trả lời đúng!
                      </p>
                    </>
                  )}
                </>
              ) : (
                <>
                  {questions[currentQuestionIndex].explanation ? (
                    <>
                      <div className="relative">
                        <img
                          src="/images/Modal/8.png"
                          alt="Correct with Explanation"
                          className="w-[500px] lg:w-[300px]"
                        />
                        <p className=" w-[43%] absolute top-[33%] right-0 mr-4 mt-4 text-green-500 text-xs font-bold">
                          {questions[currentQuestionIndex].explanation}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        src="/images/Modal/10.png"
                        alt="Incorrect"
                        className="w-[200px]"
                      />
                      <p className="text-red-500 font-bold">
                        Bạn đã trả lời sai!
                      </p>
                    </>
                  )}
                </>
              )}
              <button
                onClick={handleNextQuestion}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
              >
                Câu tiếp theo nào
              </button>
            </div>
          </div>
        )}

        {/* Modal khi hết lượt */}
        {outOfLives && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="p-6 bg-white rounded-lg flex flex-col items-center h-[400px]">
              <img
                src="/images/hetluot/2.png"
                alt="Out of Lives"
                className="w-[300px]"
              />
              <p className="text-red-500 font-bold">Bạn đã hết tim rùi!</p>
              <button
                onClick={handleFinish}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
              >
                Quay lại trang chủ
              </button>
            </div>
          </div>
        )}

        {/* Modal khi hoàn thành */}
        {completed && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="p-6 bg-white rounded-lg flex flex-col items-center">
              <img
                src="/images/hetluot/1.png"
                alt="Completed"
                className="w-[200px]"
              />
              <p className="text-green-500 font-bold">
                Chúc mừng! Bạn đã hoàn thành!
              </p>
              <button
                onClick={handleFinish}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
              >
                Quay lại trang chọn level
              </button>
            </div>
          </div>
        )}
        {/* Background Image (like the grass and trees) */}
        <div className="absolute bottom-0 left-0 right-0">
          <img src="/images/question/dat.png" alt="Ground" className="w-full" />
        </div>
      </div>
    </div>
  );
}

export default QuizScreen;
